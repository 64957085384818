.language-selector {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  z-index: 1000;
}

.language-selector span {
  cursor: pointer;
  font-size: 16px;
  color: #6e6e6e;
  font-weight: 400;
  transition: color 0.2s ease, font-weight 0.2s ease;
}

.language-selector span:hover {
  color: #333333;
}

.language-selector .active {
  font-weight: 700;
  color: #000000;
}

@media (max-width: 768px) {
  .language-selector {
    gap: 8px;
    top: 10px;
    right: 10px;
    padding: 8px;
  }

  .language-selector span {
    font-size: 14px;
  }
}
