/* Button Styles */
.logout-btn {
  background-color: transparent;
  color: #ff4c4c;
  border: none;
  padding: 8px;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
}

.logout-btn i {
  font-size: 24px;
}

.logout-btn:hover {
  color: #ff2a2a;
}

/* Tooltip Styles */
.tooltip-text {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px 8px;
  border-radius: 4px;
  position: absolute;
  top: 100%; /* Position below the button */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 12px;
  z-index: 1001;
  opacity: 0;
  transition: opacity 0.3s ease;
  margin-top: 4px;
}

.logout-btn:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
