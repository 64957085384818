.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.details-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 20px;
  min-width: 300px;
  max-height: 500px;
  width: 100%;
}

.map-section {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  gap: 20px;
  justify-content: space-between;
}

.map-container {
  width: 100%;
  max-height: 500px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.close-btn {
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  display: block;
  text-align: center;
  transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: #c82333;
}

.logout-btn {
  background-color: transparent;
  color: #ff4c4c;
  border: none;
  padding: 8px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.2s ease;
}

.logout-btn:hover {
  color: #ff2a2a;
}

.logout-btn i {
  font-size: 24px;
}

.panic-btn {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.panic-btn:hover {
  background-color: darkred;
}

.button-section {
  width: 100%;
  height: 50px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.finalize-btn,
.exit-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.finalize-btn {
  width: 100%;
  background-color: #28a745;
}

.finalize-btn:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .details-container {
    display: flex;
    margin-right: 0;
    width: 100%;
    max-height: 1000px;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .map-section {
    margin-top: 0;
    width: 100%;
    display: block;
    flex-direction: column;
    align-items: center;
  }

  .map-container {
    height: 300px;
  }

  .close-btn {
    width: 100%;
    padding: 12px;
    font-size: 14px;
  }

  .logout-btn {
    font-size: 20px;
    padding: 6px;
  }

  .button-section {
    margin-top: 20px;
    flex-direction: column;
    gap: 10px;
  }
}

.navigationButtons {
  display: none;
}

@media (max-width: 1024px) {
  .navigationButtons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
  }
}
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease forwards;
}

.modalContent p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #333;
  line-height: 1.5;
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.confirmButton {
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirmButton:hover {
  background-color: #218838;
}

.cancelButton {
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancelButton:hover {
  background-color: #5a6268;
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}

.navButton {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
}

.navButton:hover {
  background-color: #f9f9f9;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.navIcon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.buttonText {
  font-size: 16px;
  color: #333;
}
