.map-container {
  width: 700px;
  height: 700px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.marker-content {
  position: relative;
  display: inline-block;
  background-color: #d60303;
  border: 2px solid #555555;
  border-radius: 6px;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  white-space: nowrap;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.marker-content.home {
  background-color: #ffffff;
  color: #000000;
}

.marker-content::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #555555;
}

.marker-content::before {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #ffffff;
}

@media (max-width: 768px) {
  .fullscreenBtn {
    background: none rgb(255, 255, 255);
    border: 0px;
    margin: 10px;
    padding: 0px;
    text-transform: none;
    appearance: none;
    position: absolute;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    height: 40px;
    width: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    overflow: hidden;
    top: 0px;
    right: 0px;
  }

  .fullscreenIcon {
    width: 18px;
    height: 18px;
  }

  .map-container {
    height: 300px;
  }
}
