.client-details-container {
  position: relative;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 50%;
}

.client-details-text {
  position: relative;
  text-align: left;
}

.logout-btn {
  background-color: transparent;
  color: #ff4c4c;
  border: none;
  padding: 8px;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
}

.logout-btn i {
  font-size: 24px;
}

.logout-btn:hover {
  color: #ff2a2a;
}

.client-details-container img {
  max-width: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.client-details-container p {
  font-size: 18px;
  color: #666;
  margin-bottom: 10px;
}

.client-photo-thumbnail {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
  display: block;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Modal overlay */
.modal-overlay-client {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content-client {
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.modal-content-client img.client-photo-fullscreen {
  width: 80%;
  height: auto;
  max-height: 80%;
  min-width: 500px;
  min-height: 500px;
  border-radius: 8px;
  object-fit: contain;
  object-position: center;
}

.modal-overlay-client {
  cursor: pointer;
}

@media (max-width: 768px) {
  .client-details-container {
    padding: 15px;
    width: 100%;
  }

  .client-details-container h2{
   font-size: 19px;
  }

  .client-details-container img {
    max-width: 100px;
  }

  .client-details-container p {
    font-size: 16px;
  }

  .client-photo-thumbnail {
    width: 100px;
    height: 100px;
  }
  .modal-content-client img.client-photo-fullscreen {
    width: 90%;
    min-width: 300px;
    min-height: 300px;
  }
}

@media (max-width: 480px) {
  .client-details-container {
    width: 100%;
  }
  .client-photo-thumbnail {
    width: 100px;
    height: 100px;
  }

  .modal-content-client img.client-photo-fullscreen {
    width: 100%;
    max-height: 80%;
    min-width: 300px;
    min-height: 300px;
  }
}
