.loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #007bff;
    margin: 20px;
}

.loading-spinner p {
    margin-top: 10px;
    font-size: 18px;
}
