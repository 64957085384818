.body-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.code-input-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.code-input-container input[type='text'] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  display: block;
  margin: 0 auto;
  width: 50%;
  font-size: 20px;
}

.code-input-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.code-input-container button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .code-input-container {
    padding: 15px;
  }

  .code-input-container input[type='text'] {
    width: 50%;
    font-size: 20px;
  }

  .code-input-container button {
    padding: 10px 15px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .code-input-container {
    padding: 10px;
  }

  .code-input-container input[type='text'] {
    width: 50%;
    font-size: 20px;
  }

  .code-input-container button {
    padding: 8px 12px;
    font-size: 12px;
  }
}
