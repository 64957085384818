* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

body {
    background-color: #f4f4f4;
    padding: 20px;
    line-height: 1.6;
}

h1,
h2 {
    color: #333;
    margin-bottom: 20px;
}

.button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.finalize-btn {
    background-color: #28a745;
}

.finalize-btn:hover {
    background-color: #218838;
}

.exit-btn {
    background-color: #dc3545;
}

.exit-btn:hover {
    background-color: #c82333;
}

.card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.card-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.card-text {
    font-size: 16px;
    margin: 5px 0;
}

.status-open {
    color: #ff0000;
}

.status-closed {
    color: #28a745;
}

@media (max-width: 768px) {
    .button {
        width: 100%;
        font-size: 14px;
    }

    .card {
        padding: 15px;
    }

    body {
        padding: 10px;
    }

    h1,
    h2 {
        font-size: 24px;
        margin-bottom: 15px;
    }
}
