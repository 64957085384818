.panic-screen {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-left, .top-right {
    flex: 1;
}

.top-right {
    text-align: right;
}

.top-right h3 {
    color: #ff0000;
    font-size: 18px;
}

.button-section {
    width: 100%;
    height: 50px;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.finalize-btn, .exit-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.finalize-btn {
    width: 100%;
    background-color: #28a745;
}

.finalize-btn:hover {
    background-color: #218838;
}

.exit-btn {
    background-color: #dc3545;
}

.exit-btn:hover {
    background-color: #c82333;
}

@media (max-width: 768px) {
    .panic-screen {
        padding: 15px;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .top-left, .top-right {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .top-right h3 {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .button-section {
        margin-top: 20px;
        flex-direction: column;
        gap: 10px;
    }

    .finalize-btn, .exit-btn {
        width: 100%;
        font-size: 14px;
        padding: 12px;
    }
}
