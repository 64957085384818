.emergency-details-container {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Title styling */
.emergency-details-container h2 {
  font-size: 24px;
  color: #333;
  text-align: left;
  margin-bottom: 15px;
}

/* Title styling */
.emergency-details-container h3 {
  font-size: 21px;
  color: #666;
  text-align: left;
  margin-bottom: 10px;
}

/* Contact information styling */
.emergency-details-container p {
  font-size: 18px;
  color: #666;
  margin-bottom: 10px;
}

/* Flex container for two columns */
.details-columns {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Column styling */
.details-column {
  flex: 1;
}

/* Styling for each list (Places and Cars) */
.emergency-details-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* List item styling */
.emergency-details-container li {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  min-height: 206px;
}

.thumbnail {
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
}

.thumbnail:hover {
  transform: scale(1.1);
}

/* Flex layout for photo gallery */
.photo-gallery {
  display: flex;
  gap: 8px;
  max-width: 20px;
  flex-wrap: nowrap;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-image {
  max-width: 500px;
  max-height: 500px;
  height: auto;
  border-radius: 8px;
}

/* Exit button styling */
.exit-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease;
}

.exit-button:hover {
  color: #666;
}

/* Navigation button styles for modal */
.modal-nav-button {
  background: none;
  border: none;
  font-size: 2rem;
  color: #333;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 15px;
  transition: color 0.3s ease;
  z-index: 1001;
}

.modal-nav-button:hover {
  color: #666;
}

.modal-nav-button.left-button {
  left: 10px;
}

.modal-nav-button.right-button {
  right: 10px;
}

/* Prevent scrolling when modal is open */
.no-scroll {
  overflow: hidden;
  height: 100vh;
}
/* Mobile responsiveness */
@media (max-width: 768px) {
  .emergency-details-container {
    padding: 15px;
  }

  .emergency-details-container h2 {
    font-size: 20px;
  }

  .emergency-details-container p {
    font-size: 16px;
  }

  .emergency-details-container li {
    padding: 12px;
  }

  .thumbnail {
    width: 80px;
    height: 80px;
  }

  .details-columns {
    flex-direction: column;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    max-width: 90%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .modal-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 8px;
  }

  /* Exit button styling */
  .exit-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 0.8rem;
    color: #333;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.3s ease;
  }

  .exit-button:hover {
    color: #666;
  }

  .modal-nav-button {
    display: none;
  }

  @keyframes slide-left {
    from { transform: translateX(0); }
    to { transform: translateX(-100%); }
  }

  @keyframes slide-right {
    from { transform: translateX(0); }
    to { transform: translateX(100%); }
  }

  .swipe-left {
    animation: slide-left 0.3s ease-in-out;
  }

  .swipe-right {
    animation: slide-right 0.3s ease-in-out;
  }
}

/* Smaller mobile screens */
@media (max-width: 480px) {
  .emergency-details-container h2 {
    font-size: 18px;
  }

  .emergency-details-container p {
    font-size: 14px;
  }

  .emergency-details-container li {
    padding: 10px;
  }

  .thumbnail {
    width: 80px;
    height: 80px;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    max-width: 90%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .modal-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 8px;
  }

  /* Exit button styling */
  .exit-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 0.8rem;
    color: #333;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.3s ease;
  }

  .exit-button:hover {
    color: #666;
  }

  .modal-image {
    max-width: 100%;
    height: auto;
  }

  .modal-nav-button {
    display: none;
  }


  @keyframes slide-left {
    from { transform: translateX(0); }
    to { transform: translateX(-100%); }
  }

  @keyframes slide-right {
    from { transform: translateX(0); }
    to { transform: translateX(100%); }
  }

  .swipe-left {
    animation: slide-left 0.3s ease-in-out;
  }

  .swipe-right {
    animation: slide-right 0.3s ease-in-out;
  }
}
